// Initializes the chat widget on the webpage.
const initializeWidget = () => {
  // Append required CSS and JS files to the head and body respectively
  loadResources();

  // Create main container and UI elements for the widget
  const journeyContainer = createWidgetContainer();
  const chatButton = createChatButton();
  const chatWidget = createChatWidget();
  const chatBubble = createWelcomeChatBubble();

  // Append UI elements to the container
  journeyContainer.append(chatBubble, chatButton, chatWidget);
  document.body.append(journeyContainer);

  // Setup resize event listener for responsive design
  setupResponsiveBehavior();
};

// Loads necessary CSS and JS resources.
const loadResources = () => {
  const head = document.head;
  head.appendChild(createCSSLink("{{ journey_ai_widget_css }}"));
  document.body.appendChild(createJSScript("{{ fontawesome_js }}"));
  document.body.appendChild(createJSScript("{{ journey_ai_widget_js }}"));
};

/**
 * Creates a link element for CSS.
 * @param {string} href - The URL of the CSS file.
 * @returns {HTMLElement} The link element.
 */
const createCSSLink = href => {
  let link = document.createElement('link');
  link.type = 'text/css';
  link.rel = 'stylesheet';
  link.href = href;
  return link;
};

/**
 * Creates a JavaScript script element.
 * @param {string} src - The URL of the JavaScript file.
 * @returns {HTMLElement} The script element.
 */
const createJSScript = src => {
  let script = document.createElement("script");
  script.src = src;
  return script;
};

const createChatButton = () => {
    let chatButton = document.createElement("div");
    chatButton.id = "chat-button";
    // chatButton.classList.add("journey-color");
    chatButton.style.width = "50px";
    chatButton.style.height = "50px";
    chatButton.innerHTML = `
      <img class="journey-button" src="{{journey_ai_icon}}" />
      <img id="journey-hide-button" class="journey-chevron hidden" src="{{chevron_down_ai_icon}}">
      <span class="tooltip-journey-hide custom-tooltip">Hide</span>
      <span id="chat-badge" class="hidden">1</span>`;

    // <span class="tooltip-chat-badge custom-tooltip">(1) New Message</span>

    return chatButton;
  };

  const createChatWidget = () => {
    let chatWidget = document.createElement("iframe");
    chatWidget.id = "chat-widget";
    // chatWidget.className = "open";
    chatWidget.src = "{{ chat_file }}"; // Adjust this as needed
    // chatWidget.src = "http://127.0.0.1:5000/yamato/ovens/chat.html"; // Adjust this as needed

    return chatWidget;
  };

  const createWelcomeChatBubble = () => {
    let chatBubble = document.createElement("div");
    chatBubble.className = "hidden";
    chatBubble.id = "chat-bubble";
    chatBubble.innerHTML = `
      <span id="chatbubble-text"></span>
      <span id="chatbubble-label">By: <a href="https://www.jbbgi.com/journey-ai" target="_blank">Customer Journey AI</a></span>
      <img id="chatbubble-open-icon" class="chevron-up" src="{{chevron_up_ai_icon}}"/>
      <span class="tooltip-bubble-hide custom-tooltip">Expand</span>
    `;

    return chatBubble;
  };

    const createWidgetContainer = () => {
    let container = document.createElement("div");
    container.id = "journey-ai-container";
    // chatButton.classList.add("journey-color");

    return container;
  };

// Sets up responsive design behavior on window resize.
const setupResponsiveBehavior = () => {
  window.addEventListener("resize", checkScreenSize);
  // Trigger resize event initially to set up correct state
  setTimeout(() => window.dispatchEvent(new Event('resize')), 1500);
};

// Checks the screen size and adjusts the iframe class for responsive design.
const checkScreenSize = () => {
  const ai_iframe = document.querySelector("iframe#chat-widget");

  const message = {
    mobile: document.body.scrollWidth <= 480,
    width: document.body.scrollWidth,
  };

  ai_iframe.onload = function() {
    // Send a message to the iframe securely using the server's domain as the target origin
    let targetOrigin = "{{ server_domain }}";
    ai_iframe.contentWindow.postMessage(JSON.stringify(message), targetOrigin);
  };

  const isMobile = navigator.userAgent.toLowerCase().includes('mobile');
  const screenWidth = window.screen.width;

  // Exclude laptops and computers with screen widths above 1024 pixels
  if ((isMobile || screenWidth <= 1024) && Math.min(window.screen.width, window.screen.height) <= 480) {
    ai_iframe.classList.add("responsive-height");
  } else {
    ai_iframe.classList.remove("responsive-height");
  }
};

// Initialize widget based on document readiness
if (document.readyState === "loading") {
  // Initialize after DOM is loaded
  document.addEventListener("DOMContentLoaded", initializeWidget);
} else {
  // Initialize immediately
  initializeWidget();
}
